/* autoprefixer grid: autoplace */

:root {
	--fontFamilyBase: Khand, sans-serif;
	--fontFamilySecond: Hind, sans-serif;
}

.links-block a {
	color: #B4262F;
}

html, body {
	font-family: var(--fontFamilyBase);
	min-height: 100%;
	height: 100%;
    scroll-behavior: smooth;
	-webkit-overflow-scrolling: touch;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: none;
}

body {
	background: #F5F5F5;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

* {
	-webkit-tap-highlight-color: transparent;
}

#root {
	min-height: 100%;
	display: flex;
	flex-direction: column;
}

p {
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.2px;
}

.MuiButtonBase-root.MuiMenuItem-root{
	font-size: 16px;
}

@media (max-width: 880px) {
	.MuiButtonBase-root.MuiMenuItem-root{
		font-size: 12px;
		min-height: 0;
	}
}

button {
	cursor: pointer;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	transition: background-color 5000s ease-in-out 0s;
}

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select,
select:focus,
textarea {
	font-size: 16px !important;
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}